import React, { useState } from "react";
import Container from "../../components/container";
import Intro from "../../components/intro";
import { graphql, Link, navigate } from "gatsby";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import OneQuestionForm from "../../components/OneQuestionForm";

export default function Taza1Fin() {
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");

  const handleChangePassword = (e) => {
    setError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      password.toUpperCase().includes("LUA") ||
      password.toUpperCase().includes("LÚA")
    ) {
      setError(false);
      navigate("/");
    } else {
      setError(true);
    }
  };

  return (
    <Container>
      <section className="flex-col flex items-center md:justify-between mt-8 mb-16 md:mb-12">
        <h1 className="text-6xl font-bold tracking-tighter leading-tight md:pr-8 text-center">
          Código 2.
        </h1>
        <img
          className="mt-5 rounded-2xl "
          src="/bombilla.jpg"
          title="Borracho"
          alt="Borracho"
        />

        <div className="bg-indigo-50 mt-4 rounded-2xl">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 ">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
              <span className="block">Guardad este código, os hará falta:</span>
              <span className="block text-indigo-600">PAYASOS</span>
            </h2>
            <div className="mt-8 flex">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/prueba-final"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Ir a la prueba final
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
}
